<template>
  <div class="product">
    <div class="topImg">
      <img
        class="productImg"
        src="../assets/imgs/product/product_banner_img.gif"
      />
      <div class="topImgMask"></div>
      <div class="text">
        <h1>工具助推效率提升</h1>
      </div>
    </div>
    <div class="productsIntroduction">
      <img class="show" src="../assets/imgs/product_img.png" />
      <h3>核心产品</h3>
      <dl>
        <dd class="hiden">
          <img src="../assets/imgs/wolb_logo_img.png" alt="" />
        </dd>
        <dt>
          <p>
            WOLB 缩写自“Well Organized Life Booster” <br class="hiden" />
            中译为“高效人生加速器” <br class="hiden" />
            它是一款集事件安排、日历提醒、人脉管理及纪要待办功能于一体的效率管理工具。
            <br class="hiden" />
          </p>
        </dt>
        <dd class="hiden"><img src="../assets/imgs/product_img.png" /></dd>
      </dl>
    </div>
    <ProductTab class="hiden" />
    <!-- 轮播图 -->
    <HomeSwiper class="show" />
    <!-- 产品内容 -->
    <div class="productCont">
      <h2>
        多种特色功能<br class="show" /><span class="hiden">，</span>提升<span
          class="hiden"
          >您的</span
        >工作和生活效率
      </h2>
      <div class="contList">
        <dl v-for="(item, index) in productCont" :key="index">
          <dt><img :src="item.icon" alt="" /></dt>
          <dd>
            <p>{{ item.title }}</p>
            <p>{{ item.text }}</p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="subText">
      <h2>帮助千万用户快速成长</h2>
      <img src="../assets/imgs/sub_text_icon.png" class="subTextIcon" />
      <div class="subBottomText">
        <img src="../assets/imgs/sub_text_icon_2.png" class="subTextIcon2" />
        <img src="../assets/imgs/sub_text_icon_3.png" class="subTextIcon3" />
        <div class="phtonText show">
          <p>
            有目标的人生才有方向,有规划的人生才更出色。<br />
            如果您希望自己能够快速成长,并愿意为此投入精力做规划、做整理、做总结，那么您就值得拥有<br />
            一款WOLB工具来帮助自己，使自己成为一个对人生负责的人，加速让自己的成就得到更高的提升。
          </p>
        </div>
        <div class="hiden">
          <p>
            有目标的人生才有方向，有规划的人生才更出色。如果您希望自己能够快速成长，并愿意
          </p>
          <p>
            为此投入精力做规划、做整理、做总结，那么您就值得拥有一款WOLB工具来帮助自己，使自己
          </p>
          <p>
            成为一个对人生负责的人，加速让自己的成就得到更高的提升。
          </p>
        </div>
      </div>
    </div>
    <!-- 下载 -->
    <div class="download">
      <img src="../assets/imgs/sub_text_icon.png" class="downloadImg hiden" />
      <div class="cont">
        <h2>
          无论您到哪儿<br class="show" /><span class="hiden"> ，</span
          >WOLB都能伴您左右
        </h2>
        <p>您可在iPhone、Android、iPad、Mac、Windows专注高效管理个人工作和生活</p>
        <div class="downloadBtn showBlock" @click="toDownload">
          下载应用
        </div>
      </div>
    </div>
    <div class="downloadApp hiden">
      <Download />
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import ProductTab from "../components/ProductTab.vue";
import Download from "../components/Download.vue";
import HomeSwiper from "../components/HomeSwiper.vue";
import offlineIcon from "../assets/imgs/offline_icon.png";
import multideviceIcon from "../assets/imgs/multidevice_icon.png";
import calendarIcon from "../assets/imgs/calendar_icon.png";
import commissionIcon from "../assets/imgs/commission_icon.png";
import incidentIcon from "../assets/imgs/incident_icon.png";
import summaryIcon from "../assets/imgs/summary_icon.png";
import templateIcon from "../assets/imgs/template_icon.png";
export default {
  name: "Product",
  components: { ProductTab, Download, HomeSwiper },
  setup() {
    const router = useRouter();
    const productCont = [
      {
        icon: offlineIcon,
        title: "离线操作",
        text: "没有网络也能使用",
      },
      {
        icon: multideviceIcon,
        title: "多端登录",
        text: "支持手机/平板/电脑同时登录",
      },
      {
        icon: templateIcon,
        title: "人脉模板",
        text: "强大的人脉模板管理",
      },
      {
        icon: incidentIcon,
        title: "互动事件",
        text: "查看和人脉的过往事件",
      },
      {
        icon: summaryIcon,
        title: "事件关联纪要",
        text: "事件内支持添加纪要",
      },
      {
        icon: commissionIcon,
        title: "纪要关联待办",
        text: "纪要内支持添加待办",
      },
      {
        icon: calendarIcon,
        title: "自定义日历设置",
        text: "自定义起始日和起始时间点",
      },
    ];
    const toDownload = () => {
      router.push({ name: "DownloadApp" });
    };
    return {
      toDownload,
      productCont,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.product {
  .topImg {
    position: relative;
    height: 543px;
    overflow: hidden;
    .topImgMask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.1);
      box-shadow: 0.25px 45px rgba($color: #000000, $alpha: 0.1);
      backdrop-filter: blur(2px);
      z-index: 2;
    }
    .productImg {
      width: 100%;
      position: absolute;
      top: -120px;
      z-index: 1;
    }
    .text {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 3;
      h1 {
        font-size: 86px;
        color: #fff;
        line-height: 2.18;
        font-weight: 500;
        letter-spacing: 10px;
      }
    }
  }
}
.downloadApp {
  background-color: #f9faff;
}

.productCont {
  background-color: #f9faff;
  padding-top: 126px;
  padding-bottom: 135px;
  h2 {
    color: #192646;
    font-size: 57px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 100px;
  }
  .contList {
    text-align: center;
    dl {
      display: inline-flex;
      vertical-align: top;
      min-width: 388px;
      padding: 0 5px;
      height: 383px;
      background-color: #fff;
      border-radius: 21px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      &:not(:first-child) {
        margin-left: 30px;
      }
      img {
        width: 149px;
      }
      dd {
        p {
          white-space: nowrap;
        }
        p:first-child {
          color: #37474f;
          font-size: 34px;
        }
        p:last-child {
          color: rgba(55, 71, 79, 0.72);
          font-size: 23px;
        }
      }
    }
  }
}
.product > .download {
  position: relative;
  background-color: #f7fbff;
  padding-top: 110px;

  .downloadImg {
    position: absolute;
    top: 67px;
    left: 132px;
    width: 236px;
  }
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 59px;
      color: #192646;
      font-weight: 500;
    }
    p {
      color: #8c919c;
      font-size: 25px;
      text-align: center;
      margin-top: 37px;
      margin-bottom: 70px;
    }
    .downloadBtn {
      width: 169px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      color: #fff;
      box-shadow: 0px 11px 15px 1px rgba(215, 222, 249, 1);
      border-radius: 34px;
      font-size: 16px;
      cursor: pointer;
      background: #5a76e4;
    }
  }
}
.subText {
  text-align: center;
  background-color: #fff;
  position: relative;
  padding: 99px 0;
  h2 {
    color: #192646;
    font-size: 54px;
    margin-bottom: 70px;
  }
  .subTextIcon {
    position: absolute;
    width: 110px;
    left: 103px;
    bottom: 102px;
  }
  .subTextIcon2 {
    position: absolute;
    width: 42px;
    height: 28px;
    top: 0px;
    left: 0px;
  }
  .subTextIcon3 {
    position: absolute;
    width: 42px;
    height: 28px;
    right: 0px;
    bottom: 0px;
  }
  .subBottomText {
    display: inline-block;
    position: relative;
    padding: 30px 45px;
    margin: 0 50px;
    p {
      color: #192646;
      font-size: 23px;
      line-height: 2.1;
    }
  }
}
.productsIntroduction {
  color: #192646;
  h3 {
    font-size: 47px;
    text-align: center;
    line-height: 72px;
    margin-bottom: 52px;
    padding-top: 52px;
  }
  dl {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    dt {
      width: 620px;
      margin-left: 30px;
      font-size: 22px;
      font-weight: normal;
      margin-left: 90px;
      margin-right: 60px;
    }
    dd:nth-of-type(1) {
      margin-right: 20px;
      img {
        height: 309px;
      }
    }
    dd:nth-of-type(2) {
      img {
        height: 309px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .download .cont .downloadBtn {
    width: 300px;
    height: 110px;
    line-height: 110px;
    border-radius: 55px;
    transform: scale(0.7);
  }
  .subText {
    .subTextIcon {
      width: 140px;
      bottom: 60px;
      left: 60px;
    }
  }
  .productsIntroduction {
    dl {
      margin-right: 100px;
      dt {
        width: 940px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .subText {
    padding: 70px 0;
    h2 {
      margin-bottom: 40px;
      font-size: 49px;
    }
    .subBottomText {
      padding: 30px 0px;
      margin: 0 40px;
      .phtonText p {
        font-size: 33px;
        letter-spacing: 2px;
        line-height: 52px;
      }
    }
    .subTextIcon {
      width: 110px;
      top: 75px;
      right: 58px;
      bottom: auto;
      left: auto;
    }
    .subTextIcon3 {
      bottom: 23px;
      right: -5px;
    }
    .subTextIcon2 {
      left: 0px;
    }
  }
  .productCont {
    padding-top: 50px;
    padding-bottom: 70px;
    h2 {
      font-weight: 500;
      line-height: 74px;
      margin-bottom: 50px;
      font-size: 49px;
    }
    .contList {
      text-align: left;
      padding-left: 13.3px;
      dl {
        margin-left: 30px;
        text-align: center;
        min-width: 420px;
        box-shadow: 0px 0px 77px 0px rgba(0, 0, 0, 0.06);
        margin-top: 20px;
        img {
          width: 162px;
        }
        dd p:first-child {
          font-size: 38px;
          font-weight: 500;
        }
        dd p:last-child {
          font-size: 32px;
        }
      }
    }
  }
  .product .download {
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 30px;
    .cont {
      position: relative;
      h2 {
        text-align: center;
        line-height: 85px;
        font-size: 49px;
      }
      p {
        font-size: 33px;
        width: 640px;
      }
      .downloadBtn {
        width: 352px;
        height: 141px;
        border-radius: 70px;
        box-shadow: 0px 13px 18px 2px rgba(215, 222, 249, 1);
        font-size: 36px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1.5px;
      }
    }
  }
  .showBlock.downloadBtn {
    display: flex !important;
  }
  .productsIntroduction {
    background-color: #f9faff;
    padding-bottom: 80px;
    img.show {
      width: 100%;
      height: auto;
      margin-top: -20px;
    }
    h3 {
      font-size: 49px;
    }
    dl {
      display: block;
      margin: 0;
      dt {
        width: 780px;
        margin: 0 auto;
        font-size: 33px;
        p {
          text-align: justify;
        }
      }
    }
  }
  .product .topImg {
    .text {
      h1 {
        font-size: 65px;
        letter-spacing: 2px;
      }
    }
    .productImg {
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: 1;
    }
  }
}
</style>
